import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { desktop, mobile, VIEWPORT_DESKTOP } from '../../styles/media'
import Message from './Message'
import { get } from '../../helper/api'
import Spinner, { StyledSpinner } from '../general/Spinner'
import Icon, { StyledIcon } from '../general/Icon'
import themeLight from '../../styles/theme-light'
import translate from '../../helper/translate'
import KeyBinding from '../general/KeyBinding'
import { copyToClipboard } from '../../helper/string'
import { showToast } from '../../state/actions/app'
import { SelectLabelNumber } from './SelectLabel'
import { StyledArtwork } from '../card/Artwork'

class Input extends Component {
	static propTypes = {
		autoComplete: PropTypes.string,
		clearable: PropTypes.bool,
		clickToCopy: PropTypes.bool,
		collapsable: PropTypes.bool,
		disabled: PropTypes.bool,
		errors: PropTypes.array,
		icon: PropTypes.string,
		id: PropTypes.string,
		inlineButtons: PropTypes.any,
		inputRef: PropTypes.any,
		light: PropTypes.bool,
		number: PropTypes.bool,
		onBlur: PropTypes.func,
		onChange: PropTypes.func,
		onClear: PropTypes.func,
		onKeyDown: PropTypes.func,
		onFocus: PropTypes.func,
		placeholder: PropTypes.string,
		readOnly: PropTypes.bool,
		showToast: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		triggerCommandF: PropTypes.bool,
		type: PropTypes.string,
		validateOnChange: PropTypes.string,
		value: PropTypes.any.isRequired,
		viewport: PropTypes.string.isRequired
	}

	static defaultProps = {
		autoComplete: undefined,
		clearable: false,
		clickToCopy: false,
		collapsable: false,
		disabled: false,
		errors: [],
		icon: null,
		inlineButtons: null,
		id: null,
		inputRef: null,
		light: false,
		number: false,
		onBlur: () => {},
		onChange: () => {},
		onClear: () => {},
		onKeyDown: () => {},
		onFocus: () => {},
		placeholder: '',
		readOnly: false,
		triggerCommandF: false,
		type: 'input',
		validateOnChange: ''
	}

	constructor() {
		super()

		this.state = {
			fetchingError: false,
			isFetching: false,
			warning: ''
		}

		this.inputRefFallback = React.createRef()
	}

	componentDidMount() {
		const { triggerCommandF, viewport } = this.props

		if (triggerCommandF && viewport === VIEWPORT_DESKTOP) {
			window.addEventListener('keydown', this.handleCommandF)
		}
	}

	componentWillUnmount() {
		const { triggerCommandF, viewport } = this.props

		if (triggerCommandF && viewport === VIEWPORT_DESKTOP) {
			window.removeEventListener('keydown', this.handleCommandF)
		}
	}

	handleChange = (event) => {
		this.props.onChange(event.target.value)

		if (this.props.validateOnChange) this.validate(event.target.value)
	}

	handleBlur = (event) => {
		if (this.props.onBlur) this.props.onBlur(event)
	}

	handleFocus = (event) => {
		const { onFocus } = this.props

		if (onFocus) onFocus(event)
	}

	handleClickToCopy = () => {
		const { showToast, t, value } = this.props

		copyToClipboard(value)
		showToast('success', t('general.linkCopied'))
		this.focusInput()
	}

	handleCommandF = (event) => {
		if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
			event.preventDefault()

			this.focusInput()
		}
	}

	focusInput = () => {
		const inputRef = this.props.inputRef || this.inputRefFallback

		if (inputRef && inputRef.current) {
			inputRef.current.select()
			inputRef.current.scrollIntoViewIfNeeded()
		}
	}

	validate(value) {
		clearTimeout(this.timer)

		if (this.req) {
			this.req.abort()
		}

		this.setState({
			warning: ''
		})

		this.timer = setTimeout(() => this.setState({ isFetching: true }), 300)

		this.req = get(this.props.validateOnChange + value, (response) => {
			clearTimeout(this.timer)

			if (response !== false) {
				this.setState({
					fetchingError: false,
					isFetching: false,
					warning: response.message
				})
			} else {
				this.setState({
					fetchingError: false,
					isFetching: false,
					warning: ''
				})
			}
		}, () => {
			clearTimeout(this.timer)

			this.setState({
				fetchingError: true,
				isFetching: false
			})
		})
	}

	render() {
		const { autoComplete, clearable, clickToCopy, collapsable, disabled, errors, icon, id, inlineButtons, light, number, placeholder, inputRef, onClear, onKeyDown, readOnly, triggerCommandF, t, type, value, validateOnChange, viewport } = this.props
		const { fetchingError, isFetching, warning } = this.state

		const message = errors.reduce((accumulator, error) => `${accumulator}${error} `, '')

		let validation

		if (validateOnChange && value !== '') {
			if (fetchingError) {
				validation = <ValidationStatus><Icon name="exclamation-circle" color={themeLight.orange} /></ValidationStatus>
			} else if (warning !== '') {
				validation = (
					<Message warning>
						{warning}
					</Message>
				)
			} else if (warning === '' && !isFetching) {
				validation = <ValidationStatus><Icon name="check" color={themeLight.green} /></ValidationStatus>
			}
		}

		return (
			<StyledInputContainer
				clearable={clearable}
				collapsable={collapsable}
				empty={value === ''}
				number={number}
				icon={icon}
				light={light}
				validationOnChange={validateOnChange}
			>
				<input
					id={id}
					ref={inputRef || this.inputRefFallback}
					onChange={this.handleChange}
					onKeyDown={onKeyDown}
					onBlur={this.handleBlur}
					onFocus={this.handleFocus}
					onClick={clickToCopy ? this.handleClickToCopy : undefined}
					pattern={number ? '[0-9]*' : null}
					inputMode={number ? 'numeric' : null}
					placeholder={placeholder}
					autoComplete={autoComplete}
					readOnly={readOnly}
					disabled={disabled}
					tabIndex="0"
					type={type}
					value={value}
				/>
				{icon && <Icon name={icon} size={16} onClick={this.focusInput} />}

				{(clearable || inlineButtons) && (
					<InputInlineButtons>
						{clearable && <InputInlineButton onClick={onClear} visible={!!value}><Icon name="x" size={16} /></InputInlineButton>}
						{inlineButtons}
					</InputInlineButtons>
				)}

				{isFetching && <Spinner dark size={10} />}
				{/*{triggerCommandF && viewport === VIEWPORT_DESKTOP && !isFetching && !value && !collapsable && (
					<KeyBinding onClick={this.focusInput}>
						<span>{isMac() ? 'Cmd' : t('general.commandKeyWindows')}</span>
						+
						<strong>F</strong>
					</KeyBinding>
				)}*/}
				{validation}
				{message !== '' && (
					<Message small>
						{message}
					</Message>
				)}
			</StyledInputContainer>
		)
	}
}

const InputInlineButtons = styled.div`
	position: absolute;
	right: 4px;
	top: 4px;
	bottom: 4px;

	align-items: center;
	display: flex;
	gap: 1px;
`

export const InputInlineButton = styled.button`
	background: transparent;
	border: 0;
	border-radius: 4px;
	color: ${props => props.theme.id === 'dark' ? props.theme.textSecondary : '#888'};
	cursor: pointer;
	font-size: 0.9rem;
	font-weight: 500;
	padding: 0 6px;
	transition: all 0.1s;

	height: 28px;
	min-width: 28px;

	align-items: center;
	display: flex;
	justify-content: center;

	${props => props.visible === false && `
		display: none;
		cursor: text;
	`}

	${props => props.active && `
		background: ${props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'};
		color: ${props.theme.primary};
	`}

	${desktop`
		${props => !props.active && `
			&:hover {
				background: ${props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'};
				color: ${props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'};
			}
		`}
	`}

	${mobile`
		${props => !props.active && `
			&:active {
				background: ${props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'};
				color: ${props.theme.id === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'};
			}
		`}
	`}
`

export const StyledInputContainer = styled.div`
	position: relative;
	width: fit-content;

	> input, > textarea, > select, .react-select__control:not(.react-select__control--is-focused), .DayPickerInput > input {
		background: ${props => props.theme.inputBackground};
		border-color: ${props => props.theme.inputBorderTop} ${props => props.theme.inputBorderSides} ${props => props.theme.inputBorderBottom};
		border-style: solid;
		border-radius: 4px;
		border-width: 1px;
		color: ${props => props.theme.text};
		-webkit-appearance: none;

		&::placeholder, .react-select__placeholder, > ${StyledIcon} {
			color: ${props => props.theme.textVeryLight};
			transition: color 0.1s;
		}

		&:focus {
			${props => !props.loading && `
				box-shadow: 0 0 0 1px ${props.theme.inputBorderFocus};
				border-color: ${props.theme.inputBorderFocus} !important;

				${props.theme.id === 'dark' && `
					background: ${props.theme.background};
				`}
			`}
		}

		${props => props.light && `
			border-color: ${props.theme.id === 'dark' ? props.theme.inputBackground : props.theme.backgroundLight};
		`}

		${props => props.light && !props.empty && `
			border-color: ${props.theme.inputBorderTop} ${props.theme.inputBorderSides} ${props.theme.inputBorderBottom};
		`}

		${desktop`
			&:focus {
				&::placeholder, + ${StyledIcon} {
					color: ${props => props.theme.textLight} !important;
				}
			}
		`}
	}

	.react-select__menu {
		z-index: ${props => props.theme.zLayer4};

		${props => props.theme.id === 'dark' && `
			border: 2px solid ${props.theme.inputBackground};
		`}
	}

	.react-select__menu, .react-select__control--is-focused {
		background: ${props => props.theme.background};
	}

	.react-select__option:active {
		background: ${props => props.theme.inputBackground};
	}

	.react-select__option--is-focused:not(.react-select__option--is-selected) {
		background: ${props => props.theme.selectOptionHover};
		color: ${props => props.theme.text};
	}

	.react-select__option--is-selected {
		border-radius: 2px;
	}

	.react-select__multi-value, .react-select__indicator-separator {
		background: ${props => props.theme.backgroundConcrete};
	}

	.react-select__multi-value__label {
		color: ${props => props.theme.text};
	}

	> input, > textarea, > select, .DayPickerInput > input {
		display: block;
		padding: ${props => props.validationOnChange ? '0.5rem 33px 0.5rem 0.5rem' : '0.5rem'};
		width: 100%;

		${props => props.number && `
			text-align: center;
			width: 150px;
		`}

		${props => props.clearable && !props.empty && `
			padding: 0.5rem 33px 0.5rem 0.5rem;
		`}
	}

	> select {
		height: 36px;
	}

	> input[disabled], > textarea[disabled], > select[disabled], .react-select--is-disabled .react-select__control, .DayPickerInput > input[disabled] {
        background: ${props => props.theme.backgroundDisabled};
		cursor: not-allowed !important;
		opacity: 0.5;
	}

	.react-select--is-disabled .react-select__control {
		border-color: transparent !important;
	}

	.react-select__placeholder {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: calc(100% - 1rem);
	}

	.react-select__single-value {
        width: 100%;

		${props => !props.small && `
			max-width: calc(100% - 18px);
		`}

		span:not(${StyledArtwork}) {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			${props => props.small && `
				font-size: 0.9rem;
			`}
		}
    }

	.react-select__option--is-selected ${SelectLabelNumber}, .react-select__option--is-selected ${StyledArtwork} {
		color: #FFF;
	}

	.react-select__control--is-focused input {
		color: ${props => props.theme.text} !important;
	}

	.circle-picker > span > div > span > div {
		border: 2px solid rgba(0, 0, 0, 0.1);
	}

	${StyledSpinner}, i {
		position: absolute;
		top: 13px;
		right: 10px;
	}

	> ${StyledIcon} {
		color: ${props => props.theme.textSlightlyLight};
		cursor: text;
		pointer-events: none;

		position: absolute;
		top: 50%;
		left: 0.5rem;
		margin-top: -8px;
		z-index: 5;
	}

	${KeyBinding} {
		position: absolute;
		top: 0;
		right: 0.75rem;
		bottom: 0;
	}

	.DayPickerInput {
		display: block;
	}

	.DayPickerInput-Overlay {
		top: 4px;
	}

	.DayPicker-Day--today {
		color: ${props => props.theme.primary};
	}

	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside), .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
		background: ${props => props.theme.primary};
	}

	${props => props.icon && `
		> input {
			padding-left: 2rem;
		}
	`}

	${props => props.collapsable && props.empty && `
		> input:not(:focus) {
			background: ${props.theme.backgroundVeryLight};
			border-radius: 50%;
			border-color: transparent !important;
			cursor: pointer;
			padding: 0;
			height: 35px;
			width: 35px;

			&::placeholder {
				opacity: 0;
			}
		}

		> input:not(:focus) ~ ${StyledIcon} {
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0;

			height: 20px;
			width: 20px;
		}
	`}

	${desktop`
		> input, > textarea, > select, .react-select__control, .react-select__menu, .DayPickerInput > input {
			width: ${props => props.number ? '100px' : '300px'};
			max-width: 100%;
		}

		> textarea {
			max-width: 300px;
		}

		${StyledSpinner}, i {
			right: auto;
			left: 270px;
		}

		${props => props.collapsable && `
			> input {
				width: 300px;
			}
		`}

		${props => props.collapsable && props.empty && `
			> input:not(:focus):hover {
				background: ${props.theme.backgroundLight};
			}
		`}

		&:hover {
			> input, > textarea, > select, .react-select__control:not(.react-select__control--is-focused) {
				border-color: ${props => props.theme.inputBorderHoverTop} ${props => props.theme.inputBorderHoverSides} ${props => props.theme.inputBorderHoverBottom};
			}

			> input::placeholder, .react-select__placeholder {
				color: ${props => props.theme.textLight} !important;
			}

			${props => props.collapsable && props.empty ? `
				> ${StyledIcon} {
					color: ${props.theme.textLightened};
				}
			` : `
				> ${StyledIcon} {
					color: ${props => props.theme.textLight} !important;
				}
			`}
		}
	`}

	${mobile`
		width: 100%;

		${props => props.collapsable && props.empty && `
			> input:not(:focus):active {
				background: ${props.theme.backgroundLight};
			}
		`}
	`}

	${props => props.small && `
		.react-select__control {
			min-height: 27px;

			.react-select__dropdown-indicator,
			.react-select__clear-indicator {
				padding: 4px;

				svg {
					height: 16px;
					width: 16px;
					min-width: 16px;
				}
			}

			.react-select__value-container {
				padding: 0 6px;
			}

			.react-select__input {
				margin: 0;
				padding: 0;
			}

			.react-select__single-value svg {
				height: 16px;
				width: 16px;
				min-width: 16px;
			}
		}
	`}
`

const ValidationStatus = styled.div`
	position: absolute;
	top: 9px;
    left: calc(300px - 24px);

	svg {
		height: 12px;
		width: 12px;
	}

	${mobile`
		left: calc(100% - 24px);
	`}
`

const enhance = compose(
	connect(state => ({
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		showToast
	}, dispatch)),
	translate('general')
)

export default enhance(Input)
