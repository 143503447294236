/**
 * The /mydecks page needs to reload when the user makes changes.
 * After reloading haveReloaded() is called to reset the state.
 */
export const SHOULD_RELOAD_CARD_SEARCH_HAVES = 'SHOULD_RELOAD_CARD_SEARCH_HAVES'
export const SHOULD_RELOAD_MYCOLLECTIONS = 'SHOULD_RELOAD_MYCOLLECTIONS'
export const SHOULD_RELOAD_COLLECTION_CARDS = 'SHOULD_RELOAD_COLLECTION_CARDS'
export const SHOULD_RELOAD_TARGETS = 'SHOULD_RELOAD_TARGETS'

export const shouldReload = (what) => {
	if (what === 'cardSearchHaves') {
		return {
			type: SHOULD_RELOAD_CARD_SEARCH_HAVES
		}
	}

	if (what === 'collections') {
		return {
			type: SHOULD_RELOAD_MYCOLLECTIONS
		}
	}

	if (what === 'collectionCards') {
		return {
			type: SHOULD_RELOAD_COLLECTION_CARDS
		}
	}

	if (what === 'targets') {
		return {
			type: SHOULD_RELOAD_TARGETS
		}
	}

	return {}
}

export const HAVE_RELOADED_CARD_SEARCH_HAVES = 'HAVE_RELOADED_CARD_SEARCH_HAVES'
export const HAVE_RELOADED_MYCOLLECTIONS = 'HAVE_RELOADED_MYCOLLECTIONS'
export const HAVE_RELOADED_COLLECTION_CARDS = 'HAVE_RELOADED_COLLECTION_CARDS'
export const HAVE_RELOADED_TARGETS = 'HAVE_RELOADED_TARGETS'

export const haveReloaded = (what) => {
	if (what === 'cardSearchHaves') {
		return {
			type: HAVE_RELOADED_CARD_SEARCH_HAVES
		}
	}

	if (what === 'collections') {
		return {
			type: HAVE_RELOADED_MYCOLLECTIONS
		}
	}

	if (what === 'collectionCards') {
		return {
			type: HAVE_RELOADED_COLLECTION_CARDS
		}
	}

	if (what === 'targets') {
		return {
			type: HAVE_RELOADED_TARGETS
		}
	}

	return {}
}

/**
 * Shows a global error
 */
export const SET_ERROR = 'SET_ERROR'
export const setError = error => ({
	type: SET_ERROR,
	error
})

/**
 * Hides the global error
 */
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const clearError = () => ({
	type: CLEAR_ERROR
})

/**
 * Toasts
 */
export const SHOW_TOAST = 'SHOW_TOAST'
export const showToast = (toastType, text, duration = 2000, loading = false) => ({
	type: SHOW_TOAST,
	toastType,
	text,
	duration,
	loading
})

export const HIDE_TOAST = 'HIDE_TOAST'
export const hideToast = () => ({
	type: HIDE_TOAST
})

/**
 * Enables the global loading indicator
 */
export const SET_LOADING = 'SET_LOADING'
export const setLoading = () => ({
	type: SET_LOADING
})

/**
 * Disables the global loading indicator
 */
export const DONE_LOADING = 'DONE_LOADING'
export const doneLoading = title => ({
	type: DONE_LOADING,
	title
})

/**
 * Caches data in memory to accelerate common page transitions
 */
export const SET_CACHE = 'SET_CACHE'
export const setCache = (key, value) => ({
	type: SET_CACHE,
	key,
	value
})

export const SET_VIEWPORT = 'SET_VIEWPORT'
export const setViewport = viewport => ({
	type: SET_VIEWPORT,
	viewport
})

export const SET_OFFLINE = 'SET_OFFLINE'
export const setOffline = offline => ({
	type: SET_OFFLINE,
	offline
})

export const SET_PWA_INSTALL_EVENT = 'SET_PWA_INSTALL_EVENT'
export const setPWAInstallEvent = event => ({
	type: SET_PWA_INSTALL_EVENT,
	event
})

export const SET_HOVER_CARD = 'SET_HOVER_CARD'
export const setHoverCard = (card, position, animate, hideImage = false) => ({
	type: SET_HOVER_CARD,
	card,
	position,
	animate,
	hideImage
})

export const SET_CONFIRM = 'SET_CONFIRM'
export const setConfirm = confirm => ({
	type: SET_CONFIRM,
	confirm
})

export const SET_MODAL_FORM = 'SET_MODAL_FORM'
export const setModalForm = modalForm => ({
	type: SET_MODAL_FORM,
	modalForm
})

export const SET_UPDATE_AVAILABLE = 'SET_UPDATE_AVAILABLE'
export const setUpdateAvailable = updateAvailable => ({
	type: SET_UPDATE_AVAILABLE,
	updateAvailable
})

export const SET_REFRESHING = 'SET_REFRESHING'
export const setRefreshing = refreshing => ({
	type: SET_REFRESHING,
	refreshing
})

export const SET_REFERENCE = 'SET_REFERENCE'
export const setReference = (reference, override = false) => ({
	type: SET_REFERENCE,
	reference,
	override
})

export const SET_SEASONS = 'SET_SEASONS'
export const setSeasons = (format, seasons) => ({
	type: SET_SEASONS,
	format,
	seasons
})

export const SET_PRESETS = 'SET_PRESETS'
export const setPresets = presets => ({
	type: SET_PRESETS,
	presets
})

export const SET_STORAGES = 'SET_STORAGES'
export const setStorages = storages => ({
	type: SET_STORAGES,
	storages
})

export const SET_AFFILIATE_RETURN_HASH = 'SET_AFFILIATE_RETURN_HASH'
export const setAffiliateReturnHash = affiliateReturnHash => ({
	type: SET_AFFILIATE_RETURN_HASH,
	affiliateReturnHash
})

export const SET_REFERRER_PRINT_ID = 'SET_REFERRER_PRINT_ID'
export const setReferrerPrintId = referrerPrintId => ({
	type: SET_REFERRER_PRINT_ID,
	referrerPrintId
})
