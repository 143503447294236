import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getScrollPosition } from '../../helper/scroll'
import { hideOverlay } from '../../helper/router'
import { siteHasMultipleGames } from '../../../config'
import Icon from '../general/Icon'
import { VIEWPORT_DESKTOP } from '../../styles/media'

class Link extends Component {
	static propTypes = {
		active: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.bool
		]),
		as: PropTypes.string,
		children: PropTypes.any,
		className: PropTypes.string,
		dataWeight: PropTypes.number,
		disabled: PropTypes.bool,
		disabledStyle: PropTypes.bool,
		dontPrepend: PropTypes.bool,
		draggable: PropTypes.bool,
		external: PropTypes.bool,
		externalNoIcon: PropTypes.bool,
		loading: PropTypes.bool,
		game: PropTypes.string,
		htmlFor: PropTypes.string,
		history: PropTypes.object.isRequired,
		innerRef: PropTypes.any,
		modal: PropTypes.bool,
		noFollow: PropTypes.bool,
		key: PropTypes.string,
		onBeforeNavigate: PropTypes.func,
		onClick: PropTypes.func,
		onContextMenu: PropTypes.func,
		onMouseEnter: PropTypes.func,
		onMouseOver: PropTypes.func,
		onMouseLeave: PropTypes.func,
		onFocus: PropTypes.func,
		openInNewWindow: PropTypes.bool,
		red: PropTypes.bool,
		ref: PropTypes.any,
		stopPropagation: PropTypes.bool,
		tabIndex: PropTypes.string,
		title: PropTypes.string,
		to: PropTypes.string,
		viewport: PropTypes.string.isRequired
	}

	static defaultProps = {
		active: false,
		as: null,
		className: '',
		children: null,
		dataWeight: null,
		disabled: false,
		disabledStyle: false,
		draggable: undefined,
		dontPrepend: false,
		external: false,
		externalNoIcon: false,
		loading: false,
		htmlFor: null,
		innerRef: undefined,
		game: null,
		key: '',
		modal: false,
		noFollow: false,
		onBeforeNavigate: () => {},
		onClick: () => {},
		onContextMenu: () => {},
		onMouseEnter: undefined,
		onMouseOver: undefined,
		onMouseLeave: undefined,
		onFocus: undefined,
		openInNewWindow: false,
		red: false,
		ref: undefined,
		stopPropagation: false,
		tabIndex: null,
		title: null,
		to: null
	}

	handleClick = (event) => {
		const { disabled, external, history, loading, modal, onBeforeNavigate, onClick, openInNewWindow, stopPropagation, viewport } = this.props

		if (!external) {
			event.preventDefault()
		}

		const to = this.getTo()

		if (stopPropagation) {
			event.stopPropagation()
		}

		if (!loading && !disabled) {
			onBeforeNavigate(event)

			if (to !== null && !external) {
				if (event.ctrlKey || event.metaKey || (openInNewWindow && viewport === VIEWPORT_DESKTOP)) {
					window.open(to)
				} else {
					history.replace({
						pathname: history.location.pathname,
						search: history.location.search,
						state: {
							...history.location.state,
							scrollPosition: getScrollPosition()
						}
					})

					if (modal && history.location.pathname === to) {
						hideOverlay()
					} else {
						history.push(to, {
							background: modal ? history.location.state.background || history.location : undefined,
							from: history.location.pathname
						})
					}
				}
			} else {
				onClick(event)
			}
		}
	}

	getTo = () => {
		const { dontPrepend, game, to } = this.props

		if (!siteHasMultipleGames || dontPrepend) {
			return to
		}

		return `${game ? `/${game}` : ''}${to === '/' && game ? '' : to}`
	}

	render() {
		const { active, children, dataWeight, disabled, disabledStyle, draggable, external, externalNoIcon, loading, key, className, noFollow, onContextMenu, onMouseEnter, onMouseOver, onMouseLeave, onFocus, innerRef, red, ref, as, htmlFor, tabIndex, title } = this.props

		const to = this.getTo()

		return (
			<StyledLink
				href={!loading ? to : null}
				onContextMenu={onContextMenu}
				onClick={this.handleClick}
				key={key}
				className={className}
				disabled={disabled}
				disabledStyle={disabledStyle}
				draggable={draggable}
				loading={loading ? 1 : 0}
				red={red ? 1 : undefined}
				onMouseOver={onMouseOver}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				onFocus={onFocus}
				ref={innerRef || ref}
				rel={noFollow || external ? `nofollow${external ? ` noopener` : ''}` : undefined}
				as={as}
				htmlFor={htmlFor}
				external={external}
				target={external ? '_blank' : undefined}
				tabIndex={tabIndex}
				data-tooltip={!active ? title : null}
				data-tooltip-location={title ? 'bottom' : undefined}
				data-weight={dataWeight}
			>
				{children}
				{external && !externalNoIcon && <Icon name="external-link" />}
			</StyledLink>
		)
	}
}

export const StyledLink = styled.a`
	cursor: ${props => !!(props.href || props.onClick) ? 'pointer' : 'default'};

	${props => props.external ? `
		svg {
			margin-left: 0.15rem;
			vertical-align: text-bottom;
		}
	` : ''}

	${props => props.loading ? `
		cursor: wait;
	` : ''}

	${props => props['data-tooltip'] ? `
		position: relative;
	` : ''}

	${props => (props.disabled || props.disabledStyle) && `
		cursor: default;
		text-decoration: none;

		${props.disabled ? `
			pointer-events: none;
		` : ''}

		&, svg {
			color: ${props.theme.textVeryLight} !important;
		}
	`}
`

const enhance = compose(
	connect(state => ({
		game: state.persist.user.settings['site.game'],
		viewport: state.app.viewport
	})),
	withRouter
)

const EnhancedCmp = enhance(Link)

export default 	React.forwardRef((props, ref) => (
	<EnhancedCmp innerRef={ref} {...props} />
))
